import React, { ReactElement } from 'react';

import styles from './MockupPhone.module.scss';

interface IProps {
  children?: ReactElement | ReactElement[];
  className?: string;
}

/*
<div class="container">
  <p>Cross Browser method</p>
  <div class="image-stack">
    <div class="image-stack__item image-stack__item--top">
      <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/110238/portrait1.jpeg" alt="">
    </div>
    <div class="image-stack__item image-stack__item--bottom">
      <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/110238/texture-waves-cropped.jpg" alt="">
    </div>
  </div>
  <p>Text can go under here yabba dabba doo.</p>
</div>
*/

const MockupPhone: React.FC<IProps> = ({ children, className = '' }) => {
  return (
    <div className={`${styles.container} ${className}`}>
      <div className={`${styles.image}`}>
        <div className={`${styles.image} ${styles.image__phone}`}>
          <img src="/iphonex_mockup_frame-2.png" alt="" />
        </div>
        <div className={`${styles.image} ${styles.image__screen}`}>{children}</div>
      </div>
    </div>
  );
};

export default MockupPhone;
