import React, { ReactNode } from 'react';

import styles from './PricePlan.module.scss';

export interface PricePlanModel {
  name: string;
  price: string;
  strikethroughPrice: string;
  promotion?: string;
  currency: string;
  condition: string;
  cta: ReactNode;
  benefits: string[];
}

interface IProps {
  pricePlan: PricePlanModel;
  className?: string;
}

const PricePlan: React.FC<IProps> = ({ pricePlan, className = '' }) => {
  const benefits = pricePlan.benefits.map((benefit, index) => {
    return (
      <li key={index}>
        <svg width="22" height="22" xmlns="https://www.w3.org/2000/svg">
          <g fill="none" fillRule="evenodd">
            <circle fill="#AEE8A6" cx="11" cy="11" r="11"></circle>
            <path
              stroke="#004053"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 11.9L9.7 15 16 8"
            ></path>
          </g>
        </svg>
        {benefit}
      </li>
    );
  });

  return (
    <div className={`${styles.container} ${className}`}>
      <div className={styles.name}>{pricePlan.name}</div>
      <div className={styles.price}>
        {pricePlan.strikethroughPrice && (
          <span className={styles.price__currency}>
            {pricePlan.strikethroughPrice} {pricePlan.currency}
          </span>
        )}
        {pricePlan.price}
        <span className={styles.price__period}>{pricePlan.currency}</span>
      </div>
      <div className={styles.condition}>{pricePlan.condition}</div>
      <div className={styles.promotion}>{pricePlan.promotion}</div>
      <div className={styles.cta}>{pricePlan.cta}</div>
      {benefits && (
        <div className={styles.benefits}>
          <ul>{benefits}</ul>
        </div>
      )}
    </div>
  );
};

export default PricePlan;
