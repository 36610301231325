import React, { ReactNode, useRef, useState } from 'react';
import Icon from './Icon';

import styles from './Question.module.scss';

interface IProps {
  question: string;
  children: ReactNode;
  className?: string;
}

const Question: React.FC<IProps> = ({ question, children, className = '' }) => {
  const [isActive, setIsActive] = useState(false);
  const [height, setHeight] = useState('0px');

  const content = useRef<HTMLDivElement>(null);

  const toggle = () => {
    setIsActive(!isActive);
    if (content && content.current) {
      setHeight(isActive ? '0px' : `${content.current.scrollHeight}px`);
    }
  };

  return (
    <div className={`${styles.container} ${className}`}>
      <div className={styles.question} onClick={toggle}>
        <div className={styles.question__title}>{question}</div>
        <div className={styles.question__icon_container}>
          <Icon className={`${styles.question__icon} ${isActive ? styles.rotate : ''}`} />
        </div>
      </div>
      <div ref={content} style={{ maxHeight: `${height}` }} className={styles.question__content}>
        <div className={styles.question__text}>{children}</div>
      </div>
    </div>
  );
};

export default Question;
