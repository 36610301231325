import React from 'react';

import styles from './Faq.module.scss';
import Question from './Question';

interface IProps {
  faqs?: any;
  className?: string;
}

const Faq: React.FC<IProps> = ({ faqs, className = '' }) => {
  return (
    <div className={`${styles.container} ${className}`}>
      {faqs.map((item: any, key: any) => (
        <Question key={key} question={item.question}>
          {item.answer}
        </Question>
      ))}
    </div>
  );
};

export default Faq;
