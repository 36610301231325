import React from 'react';
import mailtoLink from 'mailto-link';

// import { SubscribeModal } from 'gatsby-theme-newsletter/src/components/SubscribeModal';
import CallToAction from 'gatsby-theme-newsletter/src/components/CallToAction';

import Layout, { Block, Hero, ImageBlock, NarrowBlock } from '../../../components/Layout';
import SEO from '../../../components/Seo';
import { Faq, PricePlan } from '../../../components';

import styles from './index.module.scss';
import { ImageAlignment } from '../../../components/Layout/components/ImageBlock/ImageBlock';
import MockupPhone from '../../../components/MockupPhone';

const Video = (
  <MockupPhone>
    <img
      className={styles.screen}
      src="https://s3.eu-central-1.amazonaws.com/static.qrpid.io/medical-devices/videos/qrpid_medical-devices_screenrecording_01.gif"
    />
  </MockupPhone>
);

// const DemoCTA = ({ children = 'Jetzt Demo anfordern!' }) => (
//   <CallToAction target="/de/qrpid-waiter-lockdown-angebot/demo">{children}</CallToAction>
// );

const subject = 'QRpid für medizinisches Gerät: Termin für Demo vereinbaren';
const body =
  'Guten Tag!\n\n\nIch möchte gerne einen Termin für eine Demo vereinbaren. Meine Kontaktdaten lauten wie folgt:\n\n\nFirma:\n\nAnsprechpartner:\n\nTelefonnummer:\n\n\n\nMit freundlichen Grüßen';

const mailto = mailtoLink({ subject, body, to: 'support@qrpid.io' });

const DemoCTA = ({ children = 'Jetzt Demo anfordern!' }) => (
  <CallToAction target={mailto}>{children}</CallToAction>
);

const pricePlans: any = {
  basic: {
    name: 'Basic',
    price: '49',
    currency: '€',
    condition: 'pro Monat / zzgl. MwSt.',
    cta: <DemoCTA>Demo anfordern</DemoCTA>,
    benefits: [
      'Bis zu 50 Geräte/Inventare verwalten',
      'Bis zu 20 Ansprechpartner verwalten',
      '1 Niederlassung verwalten',
      '1 Admin User',
      'Support bei Ersteinrichtung',
      'E-Mail Support',
    ],
  },
  pro: {
    name: 'Pro',
    price: '99',
    currency: '€',
    condition: 'pro Monat / zzgl. MwSt.',
    cta: <DemoCTA>Demo anfordern</DemoCTA>,
    benefits: [
      'Bis zu 100 Geräte/Inventare verwalten',
      'Bis zu 50 Ansprechpartner verwalten',
      'Bis zu 3 Niederlassungen verwalten',
      '3 Admin User',
      'Support bei Ersteinrichtung',
      'E-Mail Support',
    ],
  },
  custom: {
    name: 'Custom',
    price: '?',
    condition: '-',
    cta: <CallToAction>Demo buchen</CallToAction>,
    benefits: [
      'Bis zu ? Geräte/Inventare verwalten',
      'Bis zu ? Ansprechpartner verwalten',
      'Bis zu ? Niederlassungen verwalten',
      '3 Admin User',
      'Support bei Ersteinrichtung',
      'E-Mail Support',
    ],
  },
};

const faqs = [
  {
    question: 'Kann ich auch anderes Inventar mit QRpid verwalten?',
    answer:
      'Sicher. Je nach Inventar können Sie ebenfalls Bilder, Produktbeschreibungen, Seriennummern und vieles mehr hinterlegen.',
  },
  {
    question: 'Woher bekomme ich die QR Code Aufkleber für meine Geräte?',
    answer:
      'Der einfachste Weg ist, hochwertige Aufkleber bei uns zu bestellen. Diese sind bereits mit individuellen QR Codes bedruckt und sofort einsatzbereit. Grundsätzlich können Sie Ihre QR Codes natürlich auch selbst drucken und aufkleben.',
  },
  {
    question: 'Was ist, wenn ein QR Code Aufkleber versehentlich unbrauchbar wird?',
    answer:
      'Wir liefern jeden unserer QR Code Aufkleber in dreifacher Ausfertigung. Das bietet genügend Spielraum für Fehlversuche.',
  },
];

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const IndexPage = () => (
  <Layout>
    <SEO
      title="QRpid für medizinisches Gerät und sonstiges Inventar | QRpid"
      description="Verwalten Sie Ihr medizinisches Gerät und sonstiges Inventar mit den next level QR-Codes von QRpid."
      keywords={[`qrcodes`, `assets`, `devices`, `manage`]}
    />
    <Hero video={Video}>
      <h1>Verwalten Sie Ihr medizinisches Gerät und sonstiges Inventar.</h1>
      <p>
        Mit unseren praktischen QR Code Aufklebern und der cloud-basierten Verwaltungssoftware
        erfassen Sie alle Geräte im Handumdrehen. Bekleben, scannen und zuweisen. So schnell geht
        das.
      </p>
      <DemoCTA />
    </Hero>
    <Block className={styles.steps__light}>
      <NarrowBlock className="text-center">
        <h1>Status aller Geräte auf einen Blick</h1>
        <p>
          Die Leistungsfähigkeit ihrer Praxis oder Klinik ist gleichbedeutend mit der
          Einsatzbereitschaft ihrer medizinischen Geräte. Ein fehlender Überblick führt schnell zu
          Problemen bei der Durchführung von notwendingen Untersuchungen.
        </p>
      </NarrowBlock>
      <ImageBlock
        src="https://s3.eu-central-1.amazonaws.com/static.qrpid.io/medical-devices/benefits/qrpid_medical-devices_benefits_01.png"
        alignment={ImageAlignment.RIGHT}
        alt="Geräteübersicht durch QR Codes"
        className="text-left"
      >
        <h3>Praktische Erfassung mittels QR Code.</h3>
        <ul>
          <li>
            Hilft dem Geräteverantwortlichen jederzeit den Überblick zu behalten. Besonders bei
            großen Praxen und mehreren Zweigstellen.
          </li>
          <li>Übersicht zur Einsatzfähigkeit aller Geräte.</li>
          <li>Einfache Verwaltung des Gerätestandorts (Ort, Raum etc.).</li>
          <li>Ortsunabhängig, da die Datenhaltung zentralisiert in der Cloud erfolgt.</li>
          <li>Neben medizinischem Gerät, lassen sich auch beliebige andere Geräte verwalten.</li>
          <li>Sie können sich die aktuelle Übersicht zeitgesteuert per E-Mail zusenden lassen.</li>
        </ul>
        <DemoCTA />
      </ImageBlock>
    </Block>
    <Block className={styles.steps}>
      <NarrowBlock className="text-center">
        <h1>Probleme direkt mit dem zuständigem Servicepartner lösen.</h1>
        <p>
          Manche Probleme werden nicht direkt erledigt, weil der konkrete Ansprechpartner zum
          Zeitpunkt der &ldquo;Entdeckung&rdquo; fehlt. Unklare Zuständigkeiten und veraltete
          Kontaktinformationen verstärken das Problem zusätzlich.
        </p>
      </NarrowBlock>
      <ImageBlock
        src="https://s3.eu-central-1.amazonaws.com/static.qrpid.io/medical-devices/benefits/qrpid_medical-devices_benefits_02.png"
        alt="Informationen zum Gerät mittels Web App"
        className="text-left"
      >
        <h3>Alle Informationen direkt zur Hand.</h3>
        <ul>
          <li>
            Inventarverwaltung mit automatischer Übernahme der aktuellsten Produktinformationen.
          </li>
          <li>Kontaktdaten für Service Ansprechpartner pro Gerät und Hersteller verfügbar.</li>
          <li>Direktes Melden von Störungen und Schäden für jeden Mitarbeiter möglich.</li>
          <li>Schnellerer Support durch einheitliche Vorgangsnummer möglich.</li>
          <li>
            Häufig gestellte Fragen können pro Produkt erfasst werden, um z.B. Informationen zu
            häufigen Fehlern und optimaler Reinigung zu hinterlegen.
          </li>
        </ul>
        <DemoCTA />
      </ImageBlock>
    </Block>
    <Block className={styles.steps__light}>
      <NarrowBlock className="text-center">
        <h1>Digitalisierung der Dokumentation für übersichtlicheres Qualitätsmanagement.</h1>
        <p>
          Welche Person ist auf welches Gerät eingewiesen? Wo ist das Gerätehandbuch zum Gerät
          abgelegt? Und wann ist welcher Service fällig? Diese Informationen sollten ihnen jederzeit
          zur Verfügung stehen. Unabhängig davon, wo Sie sich gerade befinden.
        </p>
      </NarrowBlock>
      <ImageBlock
        src="https://s3.eu-central-1.amazonaws.com/static.qrpid.io/medical-devices/benefits/qrpid_medical-devices_benefits_03.png"
        alignment={ImageAlignment.RIGHT}
        alt="Digitalisierung der Dokumentation"
        className="text-left"
      >
        <h3>Weniger Excel-Tabellen, mehr Automatisierung.</h3>
        <ul>
          <li>Unterstützung bei anstehenden Überprüfungen und Zertifizierungen.</li>
          <li>Übersicht aller Wartungsintervalle mit automatischer Erinnerung.</li>
          <li>Hinterlegen Sie den Ablageort des jeweiligen Gerätehandbuches.</li>
          <li>Drucken Sie passende Produktdatenblätter für Unterlagen aus.</li>
          <li>
            Login für Mitarbeiter (Gerätebeauftragter etc.) mit unterschiedlichen Berechtigungen.
          </li>
        </ul>
        <DemoCTA />
      </ImageBlock>
    </Block>
    <Block className={styles.steps__light}>
      <NarrowBlock className="text-center">
        <h1>30 Tage Geld-zurück-Garantie.</h1>
        <p>
          Wenn Sie innerhalb des ersten Monats kündigen, erhalten Sie den gesamten Betrag zurück.
          Ohne Angabe von Gründen!
        </p>
      </NarrowBlock>
      <div className="grid-container">
        <div className="grid-x grid-margin-x">
          <div className="cell small-12">
            <div className={styles.pricePlans}>
              <PricePlan className={styles.pricePlans__plan} pricePlan={pricePlans.basic} />
              <PricePlan className={styles.pricePlans__plan} pricePlan={pricePlans.pro} />
              {/* <PricePlan className={styles.pricePlans__plan} pricePlan={pricePlans.custom} /> */}
            </div>
          </div>
        </div>
      </div>
      <NarrowBlock className={`text-center`}>
        <h3>
          Einfache Einrichtung.
          <br />
          Vorhandene Ausstattung nutzen.
          <br />
          Monatlich kündbar.
        </h3>
      </NarrowBlock>
    </Block>
    <Block className={styles.steps__light}>
      <NarrowBlock className="text-center">
        <h1 className="text-center">Häufig gestellte Fragen.</h1>
        <Faq faqs={faqs} />
      </NarrowBlock>
    </Block>
    <Block className={styles.steps__light}>
      <NarrowBlock className="text-center">
        <h1>Neugierig geworden?</h1>
        <p>
          Dann machen Sie ganz einfach über unser Formular einen Termin mit uns aus. Wir melden uns
          dann schnellstmöglich und besprechen die weiteren Schritte.
        </p>
        <div>
          <DemoCTA>Termin für Demo vereinbaren</DemoCTA>
        </div>
      </NarrowBlock>
    </Block>
  </Layout>
);

export default IndexPage;
